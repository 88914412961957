<template>
  <div class="container-main-becario">
    <div class="container-tab">
      <div class="J-tab-header">
        <div class="J-tab-nav">
          <div class="J-tab-nav-item" v-for="(tab, i) in tabs" :key="i + 1" :class="{ active: tab.checked }">
            <a href="javascript:void(0)" @click="activeTabs(i)" class="J-tab-nav-link">{{ i + 1 }}</a>
            <div class="J-tab-nav-text" style="font-size: 14px">
              {{ tab.title }}
            </div>
          </div>
        </div>
        <div class="container-tab-information">
          <div v-show="tabs[0].active">
            <form @submit.prevent="activeTabs(1)" class="row J-flex-a center">
              <div class="col-md-8">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" min="5" max="255" v-model="formData.titulo"
                    placeholder="Nombre" required autocomplete="off" />
                  <label for="floatingInput">Nombre</label>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-floating">
                  <select class="form-select" id="floatingSelect" v-on:change="listMuni(formData.estado)"
                    v-model="formData.estado" required>
                    <option value="" selected disabled>Selecciona un estado</option>
                    <option v-for="(item, i) in estado" :key="i + 1" :value="item.estado">
                      {{ item.estado }}
                    </option>
                  </select>
                  <label for="floatingSelect">Estado</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating">
                  <select id="floatingSelect2" v-model="formData.ubicacion" class="form-select" required>
                    <option value="" selected disabled>Selecciona un municipio</option>
                    <option v-for="(item, i) in municipio" :key="i + 1" :value="item.municipio">
                      {{ item.municipio }}
                    </option>
                  </select>
                  <label for="floatingSelect2">Municipio</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-2">
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                    v-model="formData.modalidad" required>
                    <option value="" selected disabled>Selecciona una opción</option>
                    <option value="Presencial">Presencial</option>
                    <option value="Híbrido">Híbrido</option>
                    <option value="Home Office Permanente">Home Office Permanente</option>
                    <!-- <option value="home office permanente">Home Office Temporal (COVID-19)</option> -->
                  </select>
                  <label for="floatingSelect">Modalidad</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-2">
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                    v-model="formData.educacionMinima" required>
                    <option value="" selected disabled>Selecciona una opción</option>
                    <option value="Bachillerato">Estudiante de Bachillerato</option>
                    <option value="Licenciatura">Estudiante de Licenciatura</option>
                    <option value="Maestria">Estudiante de Maestría</option>
                  </select>
                  <label for="floatingSelect">Educación Mínima Requerida</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-3">
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                    v-model="formData.semestreMinimo" required>
                    <option value="" selected disabled>Selecciona una opción</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                  </select>
                  <label for="floatingSelect">Semestre Mínimo Requerido</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-3">
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                    v-model="formData.idiomaMinimo">
                    <option value="" selected disabled>Selecciona una opción</option>
                    <option value="Ninguno">Ninguno</option>
                    <option value="Inglés">Inglés</option>
                    <option value="Chino mandarín">Chino mandarín</option>
                    <option value="Alemán">Alemán</option>
                    <option value="Francéss">Francés</option>
                    <option value="Portugués">Portugués</option>
                    <option value="Japonés">Japonés</option>
                    <option value="Árabe">Árabe</option>
                  </select>
                  <label for="floatingSelect">Idioma Mínimo Requerido</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-3">
                  <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                    v-model="formData.nivelMinimo">
                    <option value="" selected disabled>Selecciona una opción</option>
                    <option value="Ninguno">Ninguno</option>
                    <option value="Basico">Básico</option>
                    <option value="Intermedio">Intermedio</option>
                    <option value="Avanzado">Avanzado</option>
                  </select>
                  <label for="floatingSelect">Nivel Mínimo de Idioma</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-2">
                  <select class="form-select" id="floatingSelect" v-model="formData.workDayInitial">
                    <option value="" selected disabled>Seleccione una opción</option>
                    <option v-for="(item, i) in labourDay" :key="i + 1" :value="item.day">{{ item.day }}</option>
                  </select>
                  <label for="floatingSelect">Día Inicial de Semana de Prácticas</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-2">
                  <select class="form-select" id="floatingSelect" v-model="formData.workDayFinal">
                    <option value="" selected disabled>Seleccione una opción</option>
                    <option v-for="(item, i) in labourDay" :key="i + 1" :value="item.day">{{ item.day }}</option>
                  </select>
                  <label for="floatingSelect">Día Final de Semana de Prácticas</label>
                </div>
              </div>
              <!-- <div class="col-md-6">
                <div class="form-floating mt-2">
                  <input type="time" class="form-control" id="floatingInput" v-model="formData.horarioDe"
                    placeholder="Nombre" :required="true" autocomplete="off">
                  <label for="floatingInput">Horario de Entrada</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-2">
                  <input type="time" class="form-control" id="floatingInput" v-model="formData.horarioA"
                    placeholder="Nombre" :required="true" autocomplete="off">
                  <label for="floatingInput">Horario de Salida</label>
                </div>
              </div> -->
              <div class="col-md-6">
                <div class="form-floating mt-2">
                  <select type="text" class="form-control" id="floatingInput1" v-model="formData.horarioDe"
                    placeholder="Seleccione una hora" @click="toggleHourList('horaDe')" :required="true"
                    autocomplete="off" readonly>
                    <option value="" selected disabled>Seleccione una opción</option>
                    <option v-for="hour in hours" :key="hour" @click="selectHour(hour, 'horaDe')">{{ hour }}</option>
                  </select>
                  <label for="floatingInput1">Horario de Entrada (24 hrs.)</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mt-2">
                  <select type="text" class="form-control" id="floatingInput2" v-model="formData.horarioA"
                    placeholder="Seleccione una hora" @click="toggleHourList('horaA')" :required="true"
                    autocomplete="off" readonly>
                    <option value="" selected disabled>Seleccione una opción</option>
                    <option v-for="hour in hours" :key="hour" @click="selectHour(hour, 'horaA')" style="overflow: auto">
                      {{ hour }}</option>
                  </select>
                  <label for="floatingInput2">Horario de Salida (24 hrs)</label>
                </div>
              </div>
              <div class="space-btn mt-3">
                <div class="col-4"></div>
                <div class="btn-space col-4">
                  <button class="button-sig" type="submit">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <div v-show="tabs[1].active">
            <div class="mb-2" style="display: flex; justify-content: flex-end; align-items: center; gap: 10px">
              <small style="font-style: italic; font-family: Poppins">Ingresa un título a la vacante para generar la
                información y habilidades</small>
              <div class="">
                <button class="btn btn-outline-primary"
                  style="display: flex; justify-content: center; align-items: center; gap: 2px" @click="GenerateIA"
                  :disabled="response.loading === true || formData.titulo.length < 5">
                  <span>Generar con IA</span>
                  <div class="spinner-border spinner-border-sm float-end" role="status"
                    v-show="response.loading === true">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </button>
              </div>
            </div>

            <form @submit.prevent="activeTabs(2)" class="row center">
              <div>
                <div class="col-md-12">
                  <QuillEditor id="editor" theme="snow" placeholder="Información de la vacante"
                    v-model:content="formData.descripcion" contentType="html"
                    :toolbar="['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, { align: [] }]" />
                </div>
                &nbsp; &nbsp;
                <div class="col-md-12">
                  <QuillEditor id="editor" theme="snow"
                    placeholder="Habilidades, certificaciones y/o sistemas requeridos"
                    v-model:content="formData.habilidades" contentType="html"
                    :toolbar="['bold', 'italic', 'underline', { list: 'ordered' }, { list: 'bullet' }, { align: [] }]" />
                </div>
              </div>
              &nbsp;
              <hr />
              <div class="col-md-12">
                <p style="color: gray; font-size: 15px">Beca Mensual</p>
              </div>
              <div class="form-check mb-3 d-flex justify-content-center">
                <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                  v-model="formData.privateSalary" />
                <label class="form-check-label" for="flexCheckDefault"> ¿Deseas que la beca no sea mostrada? </label>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber"
                    v-model="formData.becaDe" @blur="formatBecaDe" placeholder="0.00" autocomplete="off" />
                  <label for="floatingInput">$</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-floating mb-3">
                  <input type="text" class="form-control" id="floatingInput" @keypress="onlyNumber"
                    v-model="formData.becaA" @blur="formatBecaA" placeholder="$0.00" autocomplete="off" />
                  <label for="floatingInput">$</label>
                </div>
              </div>

              <div class="buttons-a mt-5">
                <div class="col-4">
                  <button class="button-ats" type="button" @click="activeTabs(0)">Atras</button>
                </div>
                <div class="col-4">
                  <button class="button-sig" type="submit">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <!-- Preguntas -->
          <div v-show="tabs[2].active">
            <form class="row center" @submit.prevent="activeTabs(3)">
              <div class="preguntas-preseleccion">
                <label style="color: #1b4f99; font-weight: bold; font-size: 15px">PREGUNTAS DE PRESELECCIÓN</label>
                <div class="header-preguntas">
                  <p>Al añadir preguntas de preselección, los postulantes deben responder a la preguntas.</p>
                </div>
                <div class="card card-body" style="width: 100%; margin-top: 2%" v-for="(questions, i) in dataQuestions"
                  :key="i">
                  <div>
                    <div class="form-floating mb-3 mt-3">
                      <input type="text" class="form-control" id="floatingInput" placeholder="Escribe una pregunta"
                        v-model="questions.description" autocomplete="off" v-sensibilidad required />
                      <label for="floatingInput">Escribe una pregunta</label>
                    </div>
                    <div class="col-12" style="display: flex; flex-direction: row">
                      <div class="respuesta col-4">
                        <div class="form-floating">
                          <select class="form-select" id="tipo-respuesta" aria-label="Floating label select example"
                            v-model="questions.type" required>
                            <option value="" selected disabled>Selecciona una respuesta</option>
                            <option value="num" id="num">Numérico</option>
                            <option value="options" id="options">Si/No</option>
                          </select>
                          <label for="tipo-respuesta">Tipo de Respuesta</label>
                        </div>
                      </div>
                      &nbsp; &nbsp;

                      <div class="respuesta col-8" style="display: flex" v-if="questions.type === 'options'">
                        <div class="col">
                          <div class="form-floating">
                            <select class="form-select" id="floatingSelect" aria-label="Floating label select example"
                              v-model="questions.ideal_answer" required>
                              <option value="" selected disabled>Respuesta ideal</option>
                              <option value="yes">Sí</option>
                              <option value="no">No</option>
                            </select>
                            <label for="floatingSelect">Selecciona la respuesta ideal</label>
                          </div>
                        </div>
                        &nbsp; &nbsp;
                        <div class="col" style="display: flex; justify-content: center; align-items: center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="questions.essential"
                              id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault"> Pregunta de descarte </label>
                          </div>
                        </div>
                      </div>
                      <div class="respuesta col-8" style="display: flex" v-else-if="questions.type === 'num'">
                        <div class="col">
                          <div class="form-floating">
                            <input type="number" class="form-control" id="floatingInput" autocomplete="off"
                              placeholder="Escribe la respuesta ideal" v-model="questions.ideal_answer" required />
                            <label for="floatingInput">Escribe la respuesta ideal</label>
                          </div>
                        </div>
                        &nbsp; &nbsp;
                        <div class="col" style="display: flex; justify-content: center; align-items: center">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="questions.essential"
                              id="flexCheckDefault" />
                            <label class="form-check-label" for="flexCheckDefault"> Pregunta de descarte </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 2%; width: 100%; display: flex; justify-content: center; align-items: center">
                    <box-icon name="trash" style="cursor: pointer" @click="deleteQuestion(index)"></box-icon>
                  </div>
                </div>
                <div class="col-12 J-flex center mt-3">
                  <div class="col-6 J-flex center">
                    <button class="J-btn J-btn-secondary" type="button" @click="agregateQuestion()">Agregar
                      pregunta</button>
                  </div>
                </div>
              </div>
              <div class="buttons-a mt-3">
                <div class="col-4">
                  <button class="button-ats" type="button" @click="activeTabs(1)">Atras</button>
                </div>
                <div class="col-4">
                  <button class="button-sig" type="submit">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <div v-show="tabs[3].active">
            <form @submit.prevent="activeTabs(4)" class="row center">
              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="control" class="form-label">
                    <h6>ID Vacante</h6>
                  </label>
                  <input type="text" id="control" class="form-control" v-model="stateDashboard.control"
                    :readonly="details" required />
                </div>
                <div class="col-md-6">
                  <label for="manager" class="form-label">
                    <h6>Hiring Manager</h6>
                  </label>
                  <input type="text" id="manager" class="form-control" v-model="stateDashboard.hiringManager"
                    :readonly="details" required />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="responsable" class="form-label">
                    <h6>Responsable</h6>
                  </label>
                  <input type="text" id="responsable" class="form-control" v-model="stateDashboard.responsable"
                    :readonly="details" required />
                </div>
                <div class="col-md-6">
                  <label for="supervisor" class="form-label">
                    <h6>Supervisor</h6>
                  </label>
                  <input type="text" id="supervisor" class="form-control" v-model="stateDashboard.supervisor"
                    :readonly="details" required />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="supervisor" class="form-label">
                    <h6>Unidad / División</h6>
                  </label>
                  <input list="clients" class="form-control" v-model="stateDashboard.company" :disabled="details"
                    required />

                  <datalist id="clients">
                    <option value="" selected disabled>Seleccione...</option>
                    <option v-for="state in clients" v-bind:key="state" :value="state.informationClient.nameCompany">
                      {{ state.informationClient.nameCompany }}
                    </option>
                  </datalist>
                </div>
                <div class="col-md-6">
                  <label for="area" class="form-label">
                    <h6>Área</h6>
                  </label>
                  <input type="text" id="area" class="form-control" v-model="stateDashboard.area" :readonly="details"
                    required />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6">
                  <label class="form-label" for="nivelSelect">
                    <h6>Nivel</h6>
                  </label>
                  <select class="form-select" id="nivelSelect" v-model="stateDashboard._id_nivel" :disabled="details"
                    required>
                    <option value="" selected>Seleccione...</option>
                    <option v-for="(item, i) in niveles" :key="i + 1" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>

                <div class="col-md-6">
                  <label for="start" class="form-label">
                    <h6>Fecha de Inicio</h6>
                  </label>
                  <input type="date" name="start" id="strart" class="form-control" v-model="stateDashboard.start"
                    :disabled="details" required />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-md-6">
                  <label for="totalPositions" class="form-label">
                    <h6>Total de Posiciones</h6>
                  </label>
                  <input type="number" id="totalPositions" class="form-control" v-model="stateDashboard.totalPositions"
                    :readonly="details" />
                </div>
                <div class="col-md-6">
                  <div id="tooltip" style="display: flex">
                    <label for="meta" class="form-label">
                      <h6>Días para Cubrir la Vacante</h6>
                    </label>
                    <span id="tooltipText">Días laborables</span>
                    <box-icon name="info-square" type="solid" color="#f5b70b"></box-icon>
                  </div>
                  <input type="number" id="meta" class="form-control" v-model="stateDashboard.meta" :readonly="details"
                    required />
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-md-6 mt-1">
                  <label for="minimumLimit" class="form-label">
                    <h6>Días para status amarillo</h6>
                  </label>
                  <input type="number" id="minimumLimit" class="form-control" v-model="stateDashboard.minimumLimit"
                    :readonly="details" required />
                </div>
              </div>

              <div class="row mb-2">
                <!-- <div class="col-md-6">
                  <label for="progressCandidates" class="form-label">
                    <h6>Candidatos en Progreso</h6>
                  </label>
                  <input type="number" id="progressCandidates" class="form-control"
                    v-model="stateDashboard.progressCandidates" :readonly="details">
                </div> -->
              </div>

              <div class="row mb-2">
                <div class="col-md-12">
                  <label class="form-label" for="comentarios">
                    <h6>Comentarios</h6>
                  </label>
                  <textarea type="text" id="comentarios" class="form-control" v-model="stateDashboard.comments" cols="3"
                    :readonly="details"> </textarea>
                </div>
              </div>

              <div class="buttons-a mt-3">
                <div class="col-4">
                  <button class="button-ats" type="button" @click="activeTabs(2)">Atras</button>
                </div>
                <div class="col-4">
                  <button class="button-sig" type="submit">Siguiente</button>
                </div>
              </div>
            </form>
          </div>
          <div v-show="tabs[4].active">
            <form @submit.prevent="savedData()" class="row center">
              <div class="respuesta col-12" style="display: flex">
                <div class="col" style="display: flex; justify-content: center; align-items: center">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault"
                      v-model="formData.receive_emails" />
                    <label class="form-check-label" for="flexCheckDefault"> ¿Deseas recibir correos electrónicos de esta
                      vacante? </label>
                  </div>
                </div>
              </div>
              <div class="respuesta col-12" style="display: flex">
                <div class="col" style="display: flex; justify-content: center; align-items: center">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="formData.private" />
                    <label class="form-check-label" for="flexCheckDefault"> ¿Deseas que la vacante sea confidencial? </label>
                  </div>
                </div>
              </div>
              &nbsp;
              <hr />
              <p style="text-align: center">Si deseas recibir información de esta vacante en otro email, llena el
                siguiente campo:</p>
              <div style="display: flex; justify-content: center">
                <div class="col-md-8 mt-2">
                  <div class="form-floating mb-3">
                    <input type="email" class="form-control" id="floatingInput" v-model="formData.other_email"
                      placeholder="Email" autocomplete="off" />
                    <label for="floatingInput">Email</label>
                  </div>
                </div>
              </div>

              <div class="buttons-a mt-3">
                <div class="col-4">
                  <button class="button-ats" type="button" @click="activeTabs(3)">Atras</button>
                </div>
                <div class="col-4">
                  <button class="btn-public btn btn-outline-primary" :disabled="response.loading === true">
                    <span>Publicar</span>
                    <div class="spinner-border spinner-border-sm float-end" role="status"
                      v-show="response.loading === true">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as ClientService from "../services/client.js";
import { reactive } from "vue-demi";
import { UserService } from "@/services";
import useValidate from "@vuelidate/core";
import { ref } from "vue";
import { CompanyService } from "@/services";
import moment from "moment";
moment.locale("es");

export default {
  data: () => {
    return {
      response: {
        loading: false,
      },
      dataQuestions: [],
      vacante: false,
      options: "",
      formData: {
        titulo: "",
        estado: "",
        ubicacion: "",
        modalidad: "",
        educacionMinima: "",
        semestreMinimo: "",
        idiomaMinimo: "",
        nivelMinimo: "",
        workDayInitial: "",
        workDayFinal: "",
        horarioDe: "",
        horarioA: "",
        descripcion: "",
        habilidades: "",
        becaDe: "0",
        becaA: "0",
        privateSalary: false,
        type: "Becario",
        questions: [],
        receive_emails: false,
        other_email: "",
        private: false,
        client: "",
        logo_client: false,
      },
      title: "Nueva Vacante",
      titleDetails: "Detalles de la Vacante",
      titleEdit: "Editar Vacante",
      //status: ["En progreso", "Crítico", "Holding", "Cancelado", "Terminado", "Cubierto Interno", "Feedback"],
      //niveles: ['I. Becario - Practicante', 'II. Staff', 'III. Mando Medio', 'IV. Gerente', 'V. Director', 'VI. Ejecutivo'],
      estados: [],
      clients: [],
      niveles: [],
      estatus: [],
      user: JSON.parse(localStorage.getItem("user")),
      showLink: false,
      stateDashboard: {
        id: "",
        control: "",
        responsable: "",
        supervisor: "",
        company: "",
        area: "",
        vacante: "",
        hiringManager: "",
        terna: "",
        start: new Date().toISOString().slice(0, 10),
        status: "En progreso",
        closingDate: "",
        admisionDate: "",
        nivel: "",
        comments: "",
        meta: "",
        minimumLimit: "",
        maximumLimit: "",
        _id_user: "",
        _id_vacante: "",
        details: "",
        daysToClose: "",
        viableCandidates: "",
        progressCandidates: "",
        federalEntities: "",
        totalPositions: 1,
        link: "",
        _id_nivel: "",
        _id_estatus: "",
      },
      tabs: [
        {
          title: "Sobre la Vacante",
          active: true,
          checked: true,
        },
        {
          title: "Requerimientos",
          active: false,
          checked: false,
        },
        {
          title: "Preguntas",
          active: false,
          checked: false,
        },
        {
          title: "Dashboard",
          active: false,
          checked: false,
        },
        {
          title: "Publicar",
          active: false,
          checked: false,
        },
      ],
      labourDay: [
        {
          day: "Lunes",
        },
        {
          day: "Martes",
        },
        {
          day: "Miércoles",
        },
        {
          day: "Jueves",
        },
        {
          day: "Viernes",
        },
        {
          day: "Sábado",
        },
        {
          day: "Domingo",
        },
      ],
      estado: [],
      municipio: [],
      selectedHour: "",
      showHourListDe: false,
      showHourListA: false,
      hours: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
    };
  },
  watch: {
    dataQuestions: {
      handler: function (newVal, oldVal) {
        console.log("Nuevos datos en state:", newVal);
        console.log("Datos anteriores en state:", oldVal);
      },
      deep: true,
    },
    'formData.idiomaMinimo': function (newVal) {
      if (newVal === 'Ninguno') {
        this.formData.nivelMinimo = 'Ninguno';
      } else if (this.formData.nivelMinimo === 'Ninguno') {
        this.formData.nivelMinimo = ''; // O mantener la opción seleccionada por el usuario
      }
    }
  },
  computed: {
    formattedValue: {
      get() {
        return this.value.toLocaleString("es-MX", {
          style: "currency",
          currency: "MXN",
        });
      },
      set(newValue) {
        this.value = parseFloat(newValue.replace(/[^0-9.]/g, ""));
      },
    },
  },
  setup() {
    const isOpen = ref(false);
    const state = reactive({
      name: "",
    });
    const v$ = useValidate(state);
    return {
      state,
      v$,
      isOpen,
    };
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem("user") || null);
    const {
      company: { _id: _id_company },
    } = user;
    this.getClientCompany(_id_company);
    let { company } = user;
    console.log(company.nameCompany);
    this.listEstado();
    this.getNiveles();
    this.getEstatus();
    this.lastItem();
  },
  methods: {
    async listEstado() {
      try {
        let resp = await UserService.getEstado();
        let { data } = resp.data;
        this.estado = data;
        console.log('this.estado', this.estado);
      } catch ($e) {
        console.log($e.message);
      }
    },
    async lastItem() {
      try {
        const user = JSON.parse(localStorage.getItem("user") || null);
        let resp = await CompanyService.getLasItemDashboardByUser({ userId: user._id });
        let { data } = resp.data;
        let lastControl = Number(data[0].control) + 1;
        console.log("data", data);
        this.stateDashboard.control = lastControl;
      } catch ($e) {
        console.log($e.message);
      }
    },
    async getNiveles() {
      try {
        const response = await CompanyService.getNiveles();
        const { data } = response.data;
        this.niveles = data;
      } catch (e) {
        console.log(e);
      }
    },
    async getEstatus() {
      try {
        const response = await CompanyService.getEstatus();
        const { data } = response.data;
        this.estatus = data;
      } catch (e) {
        console.log(e);
      }
    },
    async listMuni(estado) {
      const parametroCodificado = encodeURIComponent(estado);
      try {
        let resp = await UserService.getEstado({ estado: parametroCodificado });
        let { data } = resp.data;
        this.municipio = data;
      } catch ($e) {
        console.log($e.message);
      }
    },
    // async savedData() {
    //   try {
    //     this.response.loading = true;
    //     this.formData.questions = this.dataQuestions.length === 0 ? [] : this.dataQuestions;
    //     this.formData.client = this.stateDashboard.company;
    //     await UserService.createVacantes(this.formData).then((result) => {
    //       let { vacante } = result.data;
    //       this.stateDashboard._id_user = this.user._id;
    //       this.stateDashboard.vacante = this.formData.titulo;
    //       this.stateDashboard.federalEntities = this.formData.estado;
    //       this.stateDashboard._id_vacante = vacante._id;
    //       this.stateDashboard.maximumLimit = this.formData.meta;
    //       this.stateDashboard._id_estatus = this.estatus[0]._id;
    //     });
    //     await CompanyService.createVacanteDasboard(this.stateDashboard);

    //     this.$swal({
    //       position: "top-center",
    //       icon: "success",
    //       title: "Vacante Creada Correctamente",
    //       showConfirmButton: false,
    //       timer: 7000,
    //     });
    //     this.response.loading = false;
    //     this.$router.push("/vacantes/empresa");
    //   } catch (error) {
    //     this.response.loading = false;
    //     let errorMessage = "Ha ocurrido un error al crear la vacante.";
    //     if (error.response && error.response.data.errors) {
    //       errorMessage = "Errores de validación:\n" + error.response.data.errors.join('\n');
    //     } else if (error.response && error.response.data.message) {
    //       errorMessage = error.response.data.message;
    //     }
    //     this.$swal({
    //       position: "top-center",
    //       icon: "error",
    //       text: errorMessage,
    //       showConfirmButton: false,
    //       timer: 8000,
    //     });
    //   }
    // },
    async savedData() {
      try {
        const user = JSON.parse(localStorage.getItem("user") || null);
        if (!user.photo || !user.photo.link) {
          this.$swal({
            position: "top-center",
            icon: "warning",
            title: "¡Es necesario subir un logo o imagen de perfil antes de crear la vacante!",
            showConfirmButton: false,
            timer: 5000,
          });
          return; 
        }
        this.response.loading = true;
        this.formData.questions = this.dataQuestions.length === 0 ? [] : this.dataQuestions;
        this.formData.client = this.stateDashboard.company;
        await UserService.createVacantes(this.formData).then((result) => {
          let { vacante } = result.data;
          this.stateDashboard._id_user = this.user._id;
          this.stateDashboard.vacante = this.formData.titulo;
          this.stateDashboard.federalEntities = this.formData.estado;
          this.stateDashboard._id_vacante = vacante._id;
          this.stateDashboard.maximumLimit = this.formData.meta;
          this.stateDashboard._id_estatus = this.estatus[0]._id;
        });
        await CompanyService.createVacanteDasboard(this.stateDashboard);
        this.$swal({
          position: "top-center",
          icon: "success",
          title: "Vacante Creada Correctamente.<br>¡Tendrá una vigencia de 3 meses!",
          showConfirmButton: false,
          timer: 9000,
        });
        this.response.loading = false;
        this.$router.push("/vacantes/empresa");
      } catch (error) {
        this.response.loading = false;
        let errorMessage = "Ha ocurrido un error al crear la vacante.";
        if (error.response && error.response.data.errors) {
          errorMessage = "Errores de validación:\n" + error.response.data.errors.join('\n');
        } else if (error.response && error.response.data.message) {
          errorMessage = error.response.data.message;
        }
        this.$swal({
          position: "top-center",
          icon: "error",
          text: errorMessage,
          showConfirmButton: false,
          timer: 8000,
        });
      }
    },

    activeTabs(i) {
      console.log("this.stateDashboard =>", this.stateDashboard);
      this.tabs.forEach((el, index) => {
        if (i === index) {
          el.active = true;
          el.checked = true;
        } else {
          el.active = false;
        }
      });
    },
    onlyNumber(event) {
      const charCode = event.charCode;
      if (charCode < 48 || charCode > 57) {
        event.preventDefault();
      }
    },
    agregateQuestion() {
      this.dataQuestions.push({
        description: "",
        type: "",
        ideal_answer: "",
        essential: false,
      });
    },
    deleteQuestion(index) {
      this.dataQuestions.splice(index, 1);
    },
    formatBecaDe() {
      const becaDeValue = this.formData.becaDe.trim();
      if (becaDeValue !== "") {
        const parsedValue = parseInt(becaDeValue);
        if (!isNaN(parsedValue)) {
          this.formData.becaDe = parsedValue.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      }
    },
    formatBecaA() {
      const becaDeValue = this.formData.becaA.trim();
      if (becaDeValue !== "") {
        const parsedValue = parseInt(becaDeValue);
        if (!isNaN(parsedValue)) {
          this.formData.becaA = parsedValue.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          });
        }
      }
    },

    tiempoTranscurrido(start, end) {
      var from = moment.utc(start);
      var to = moment.utc(end);
      var days = 0;

      while (!from.isAfter(to)) {
        // Si no es sabado ni domingo
        if (from.isoWeekday() !== 6 && from.isoWeekday() !== 7) {
          days++;
        }
        from.add(1, "days");
      }
      return days;
    },
    async getClientCompany(filter) {
      console.log(filter);
      let resp = await ClientService.companyClientListActivo({ _id_company: filter });
      this.clients = resp.data.data;
    },
    async GenerateIA() {
      try {
        this.response.loading = true;
        let prompt = `Dame unicamente habilidades para la vacante ${this.formData.titulo}'`;
        let prompt2 = `Dame unicamente requerimientos para la vacante  ${this.formData.titulo}`;
        let resp = await CompanyService.GenerateIA({ prompt: prompt, prompt2: prompt2 });
        let { requerimientos, habilidades } = resp.data;
        this.formData.descripcion = requerimientos;
        this.formData.habilidades = habilidades;
        this.response.loading = false;
      } catch (error) {
        this.response.loading = false;
        console.error(error);
      }
    },
    toggleHourList(input) {
      if (input === "horaDe") {
        this.showHourListDe = !this.showHourListDe;
        this.showHourListA = false;
      } else {
        this.showHourListA = !this.showHourListA;
        this.showHourListDe = false;
      }
    },
    selectHour(hour, input) {
      if (input === "horaDe") {
        this.formData.horarioDe = hour;
        this.showHourListDe = false;
      } else {
        this.formData.horarioA = hour;
        this.showHourListA = false;
      }
    },
  },
};
</script>

<style land="scss" scoped>
@import "../styles/becarioform.scss";
@import "../styles/formulariodashboard.scss";
</style>
